import { Table } from 'antd';
import { useState } from 'react';
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Start Date',
        dataIndex: 'sdate',
        key: 'sdate',
        sorter: (a, b) => a.sdate.localeCompare(b.sdate),
    },
    {
        title: 'End Date',
        dataIndex: 'edate',
        key: 'edate',
        sorter: (a, b) => a.edate.localeCompare(b.edate),
    },
    {
        title: 'Plan',
        dataIndex: 'plan',
        key: 'plan',
        sorter: (a, b) => a.plan.localeCompare(b.plan),
    },
    {
        title: 'Agree',
        dataIndex: 'agree',
        key: 'agree',
        sorter: (a, b) => a.agree - b.agree,
    },
    {
        title: 'Disagree',
        dataIndex: 'disagree',
        key: 'disagree',
        sorter: (a, b) => a.disagree - b.disagree,
    },
    {
        title: "User's plan",
        dataIndex: 'image1',
        key: 'image1',
        render: (image1) => {
            return (
                <img src={image1} width={'40px'} height={'40px'}></img>
            )
        }
    },
    {
        title: 'Our plan',
        dataIndex: 'image2',
        key: 'image2',
        render: (image2) => {
            return (
                <img src={image2} width={'40px'} height={'40px'}></img>
            )
        }
    },
    {
        title: 'Result',
        dataIndex: 'result',
        key: 'result',
        sorter: (a, b) => a.result.localeCompare(b.result),
    },
];
const data = [
    {
        key: 1,
        name: 'John Brown',
        sdate: '06/06/2023',
        edate: '07/06/2023',
        plan: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        agree: 10,
        disagree: 20,
        description: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        image1: 'image1.png',
        image2: 'image2.png',
        result: 'Success'
    },
    {
        key: 2,
        name: 'Jim Green',
        sdate: '06/06/2023',
        edate: '07/06/2023',
        plan: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        agree: 10,
        disagree: 20,
        description: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        image1: 'image1.png',
        image2: 'image2.png',
        result: 'Failure'
    },
    {
        key: 3,
        name: 'Toms',
        sdate: '06/06/2023',
        edate: '07/06/2023',
        plan: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        agree: 10,
        disagree: 20,
        description: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        image1: 'image1.png',
        image2: 'image2.png',
        result: 'Failure'
    },
    {
        key: 4,
        name: 'Joe Black',
        sdate: '06/06/2023',
        edate: '07/06/2023',
        plan: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        agree: 10,
        disagree: 20,
        description: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        image1: 'image1.png',
        image2: 'image2.png',
        result: 'Success'
    },
];
const History = () => (
    <div className="container mx-auto pt-8 pb-16 px-4">
        <Table
            columns={columns}
            expandable={{
                expandedRowRender: (record) => (
                    <div>
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {record.description}
                        </p>
                        <div style={{ display: "flex" }}>
                            <img style={{ padding: "10px" }} src={record.image1} width={600} height={600} />
                            <img style={{ padding: "10px" }} src={record.image2} width={600} height={600} />
                        </div>
                    </div>
                ),
                rowExpandable: (record) => record.name !== 'Not1 Expandable',
            }}
            dataSource={data}
        />
    </div>
);
export default History;